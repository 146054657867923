import { useContext }              from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl }                 from 'react-intl';

import { createSubNavigationStructure, findT } from '@interness/web-core/src/components/_helpers';
import { LanguageContext }                     from '@interness/web-core/src/providers';
import routes                                  from '@interness/web-core/config/routes';

export const useBrandsMenu = (type) => {
  const intl = useIntl();
  const { language } = useContext(LanguageContext);
  const data = useStaticQuery(query);
  const brandData = data.allDirectusBrands.nodes.sort((a, b) => a.sort && b.sort ? a.sort - b.sort : null);
  const menu = createSubNavigationStructure(brandData, type, routes, language);
  const typeTranslation = findT(data.allDirectusBrandTypes.nodes.filter(brandType => brandType.type === type)[0].translations, language);
  const overview = {
    displayName: intl.formatMessage({ id: 'components.subnavigation.overview' }),
    path: routes[type][language]
  };

  return {
    menu,
    overview,
    typeInfo: typeTranslation
  }
};

const query = graphql`
  query {
    allDirectusBrands(filter: {id: {ne: "dummy"}, brand: {status: {eq: "published"}}}, sort: {fields: brand___brand_rating, order: DESC}) {
      nodes {
        brand {
          type {
            type
          }
          slug
          display_name
        }
      }
    }
    allDirectusBrandTypes {
      nodes {
        type
        translations {
          display_name
          language
        }
      }
    }
  }
`;
