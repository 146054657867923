import React                             from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Box, Button }                   from '@chakra-ui/react';
import { MdOutlineContactPhone }         from 'react-icons/md';

import OpeningPeriods from './OpeningPeriods';
import './styles.css';
import Heading        from '../../@interness/web-core/components/text/Heading/Heading';

const CallToAction = ({ heading, description, button, link, imageFileTitle }) => {
  const data = useStaticQuery(query);
  const image = data.directusGlobalMediaCollection.media.find(media => media.file.title.toLowerCase().trim() === imageFileTitle.toLowerCase().trim()).file.localFile.publicURL;
  const defaultImages = {
    desktop: image,
    mobile: image
  }
  return (
    <>
      <Heading tag={'h2'} icon={<MdOutlineContactPhone/>}>{heading}</Heading>
      <Box id="cta-262" sx={{
        color: '#fff !important',
        'h2,h4': {
          color: '#fff'
        },
        h4: {
          fontWeight: 'bold !important'
        }
      }}>
        <div className="cs-container">
          <div className="cs-left-section">
            <h2 className="cs-title">
              {description}
            </h2>
            <Button colorScheme="brand" as={Link} to={link} sx={{ textDecoration: 'none' }}>
              {button}
            </Button>
          </div>
          <div className="cs-content">
            <OpeningPeriods openingPeriods={data.allDirectusOpeningPeriods.nodes}/>
          </div>
          <picture className="cs-background">
            <source
              media="(max-width: 600px)"
              srcSet={defaultImages.mobile}
            />
            <source
              media="(min-width: 601px)"
              srcSet={defaultImages.desktop}
            />
            <img
              aria-hidden="true"
              loading="lazy"
              decoding="async"
              src={defaultImages.mobile}
              alt="appliance"
              width={275}
              height={132}
            />
          </picture>
        </div>
      </Box>
    </>
  )
};

CallToAction.defaultProps = {
  heading: 'Persönlicher Kontakt',
  description: 'Nehmen Sie Kontakt mit uns auf',
  button: 'Kontakt',
  link: '/kontakt',
  imageFileTitle: 'theme-sonora-call-to-action-background-default'
}

const query = graphql`
    query {
        allDirectusOpeningPeriods(filter: {id: {ne: "dummy"}}) {
            nodes {
                open
                sort
                time_open
                time_close
                day
                free_text
            }
        }
        directusGlobalMediaCollection(
            name: {eq: "theme-sonora-call-to-action-backgrounds"}
        ) {
            media {
                file: directus_files_id {
                    localFile {
                        publicURL
                    }
                    title
                }
            }
        }
    }
`;

export default CallToAction;