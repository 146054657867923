import React     from 'react';
import PropTypes from 'prop-types';

import Subnavigation from '@interness/web-core/src/components/structure/Subnavigation/Subnavigation';

import { useBrandsMenu } from '../../../hooks/useBrandsMenu';

const BrandsSubnavigation = (props) => {
  const { menu, overview, typeInfo } = useBrandsMenu(props.type);
  return (
    <Subnavigation menu={menu} overview={overview} menuTitle={`Markenwelt: ${typeInfo.display_name}`}/>
  )
};

BrandsSubnavigation.propTypes = {
  type: PropTypes.string.isRequired,
};

export default BrandsSubnavigation;
