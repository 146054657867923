import React, { useState, useEffect } from 'react';
import PropTypes                      from 'prop-types';
import AnimateHeight                  from 'react-animate-height';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { useCurrentWidth }            from 'react-socks';

import Link from '@interness/web-core/src/components/elements/Link/Link';

import * as S from './styles';

const Subnavigation = ({ overview, menu, menuTitle }) => {
  const width = useCurrentWidth();
  const breakpoint = 960;
  const [mobileMenuOpen, toggleMobileMenu] = useState(false);
  const [mounted, setMounted] = useState(false);

  const toggleMenu = () => toggleMobileMenu(!mobileMenuOpen);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <S.Nav breakpoint={breakpoint} mounted={mounted}>
        <div className='int-mobile-menu'>
          <button onClick={toggleMenu}>{menuTitle} {mobileMenuOpen ? <FaChevronUp/> :
            <FaChevronDown/>}</button>
        </div>
        <AnimateHeight height={width > breakpoint || mobileMenuOpen ? 'auto' : 0}>
          <ul>
            {overview && <li>
              <Link activeClassName={'current'} style={{ fontWeight: 'bold' }}
                    to={`${overview.path}`}>{overview.displayName}</Link>
            </li>}
            {menu.map(item => {
              return (
                <li key={item.path}><Link to={item.path} activeClassName={'current'}>{item.displayName}</Link></li>
              )
            })}
          </ul>
        </AnimateHeight>
      </S.Nav>
    </>
  )
};

Subnavigation.propTypes = {
  menu: PropTypes.array,
  menuTitle: PropTypes.string,
  overview: PropTypes.object,
};

Subnavigation.defaultProps = {
  showOverview: true,
  menuTitle: 'Menu'
};

export default Subnavigation;
