import styled     from '@emotion/styled';
import { darken } from 'polished';

export const Nav = styled.nav`
  // position: sticky;
  // top: ${props => props.theme.config.components.header.height}px;
  border-top: 1px solid lightgray;
  @media (max-width: ${props => props.breakpoint}px) {
    position: relative;
    top: 0;
    .int-mobile-menu {
      display: block !important;
    }
  }
  z-index: 5;
  width: 100%;
  background-color: var(--chakra-colors-base-100);
  overflow: hidden;
  .int-mobile-menu {
    display: ${props => props.mounted ? 'none' : 'block !important'};
    text-align: center;
    button {
      outline: none;
      border: none;
      display: inline-block;
      font-weight: bold;
      text-decoration: none;
      color: unset;
      padding: 20px;
      background-color: unset;
    }
    svg {
      vertical-align: text-top;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 0;
    li {
      list-style-type: none;
      margin: 0;
      a {
        font-size: 0.9rem;
        display: block;
        width: 100%;
        height: 100%;
        color: unset;
        padding: 20px;
        transition: background-color 0.2s;
        text-decoration: none;
        &:hover, 
        &.current {
          color: ${props => props.theme.brand_color};
          // background-color: ${props => darken(0.1, props.theme.config.global.backgroundColor)};
        }
      }
    }
  }
`;