import React                                from 'react';
import { graphql, Link }                    from 'gatsby';
import Img                                  from 'gatsby-image';
import { Box, Center, Flex, Button, Stack } from '@chakra-ui/react';

import { findT } from '@interness/web-core/src/components/_helpers';
import routes    from '@interness/web-core/config/routes';
import Logo      from '@interness/web-core/src/components/media/Logo/Logo';
import SEO       from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                 from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Spacer    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading   from '@interness/web-core/src/components/text/Heading/Heading';

import * as S       from './styles';
import CallToAction from '../../../../components/CallToAction/CallToAction';

const BrandsPageTemplate = props => {
  const { type, lang } = props.pageContext;
  const brands = props.data.allDirectusBrands.nodes;
  const brandInfo = findT(props.data.directusBrandTypes.translations, lang);
  return (
    <>
      <SEO title={brandInfo.display_name}/>
      <BrandsSubnavigation type={type}/>
      <Spacer height={20}/>
      <Wrapper width={1024}>
        <Heading
          subtitle={`bei ${findT(props.data.directusProject.translations, lang).seo_title}`}>Markenwelt {brandInfo.display_name}</Heading>
        <S.TypeDescription dangerouslySetInnerHTML={{ __html: brandInfo.description }}/>
        <Spacer height={20}/>
      </Wrapper>
      <Spacer height={40}/>
      <Wrapper width={1024}>
        <Spacer height={120}/>
        {brands.map(({ brand }, i) => {
          const isEven = i % 2 === 0;
          if (!brand.gallery_images_square[0]) {
            console.log(`Quadratic imagages missing for ${brand.display_name}`)
            return;
          }
          return (
            <Flex key={brand.display_name}
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="space-between"
                  flexDirection={isEven ? 'row-reverse' : 'row'}
                  mb={'140px'}
                  bg="base.200"
                  rounded={'2xl'}
                  position="relative"
                  maxHeight="300px">
              <Box w={['100%', '100%', '60%']} p={'12'}>
                <Center>
                  <Stack>
                    <Box maxHeight={'80px'}>
                      <Logo logo={brand.logo.localFile} title={brand.display_name} svgFill={'#000'} svgWidth="240px"
                            svgHeight="80px" imageWidth="240px"/>
                    </Box>
                    <Button variant={'ghost'} colorScheme="brand" style={{ textDecoration: 'none' }}
                            as={Link}
                            to={`${routes[brand.type.type][lang]}/${brand.slug}`}>Mehr erfahren</Button>
                  </Stack>
                </Center>
              </Box>
              <Box w={['100%', '100%', '40%']}>
                <Box rounded={'2xl'}
                     boxShadow={'2xl'}
                     overflow={'hidden'}
                     ml={['auto', 'auto', '50px']}
                     mr={['auto', 'auto', '50px']}
                     mt={['-30px', '-30px', '-130px']}
                     maxW={['200px', '200px', '100%']}
                     textAlign="center"
                >
                  <Img fluid={brand.gallery_images_square[0].file.localFile.childImageSharp.fluid}
                       alt={brand.display_name}/>
                </Box>
              </Box>
            </Flex>
          )
        })}
      </Wrapper>
      <CallToAction/>
    </>
  )
};

export const query = graphql`
    query($type: String!) {
        directusBrandTypes(type: {eq: $type}) {
            type
            translations {
                language
                display_name
                subtitle
                description
                short_description
            }
        }
        allDirectusBrands(filter: {brand: {type: {type: {eq: $type}}, status: {eq: "published"}}, id: {ne: "dummy"}}, sort: {fields: brand___brand_rating, order: DESC}) {
            nodes {
                ...BrandsCore
                brand {
                    gallery_images_square {
                        file {
                            width
                            height
                            localFile {
                                name
                                childImageSharp {
                                    fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        directusProject {
            translations {
                seo_title
                language
            }
        }
    }
`;

export default BrandsPageTemplate;