import React                from 'react';
import { FormattedMessage } from 'react-intl';

const OpeningPeriods = ({ openingPeriods }) => {
  const dayEnum = {
    '0': 'Info',
    '1': 'Montag',
    '2': 'Mo - Mi',
    '3': 'Mo - Do',
    '4': 'Mo - Fr',
    '5': 'Dienstag',
    '6': 'Di - Fr',
    '7': 'Mittwoch',
    '8': 'Donnerstag',
    '9': 'Freitag',
    '10': 'Samstag',
    '11': 'Sonntag',
    '12': 'Mo - Di',
    '13': 'Do - Fr',
    '14': 'Mo, Mi, Fr',
    '15': 'Heiligabend',
    '16': 'Silvester',
  }
  let sortedPeriods = {};
  openingPeriods.forEach(period => {
    if (period.day !== '0') {
      if (period.time_open && period.time_close) {
        if (sortedPeriods[period.day]) {
          sortedPeriods[period.day].times.push(period.time_open, period.time_close)
        } else {
          sortedPeriods[period.day] = {
            day: period.day,
            times: [period.time_open, period.time_close],
            sort: period.sort
          }
        }
      } else {
        sortedPeriods[period.day] = {
          day: period.day,
          closed: true,
          sort: period.sort
        }
      }
    }
  })
  let array = [];
  Object.keys(sortedPeriods).forEach(period => array.push(sortedPeriods[period]));
  const sortedArray = array.sort((a, b) => a.sort - b.sort);
  //console.log(sortedPeriods.sort((a, b) => a.sort - b.sort));
  const freeText = openingPeriods.find(period => period.free_text);
  return (
    <>
      <h4>Öffnungszeiten</h4>
      <ul>
        {sortedArray.map(period => {
          const custom = !Object.keys(dayEnum).find(d => d === period.day);
          return <li key={period.day} style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
            <span style={{ marginRight: '5px' }}>{custom ? period.day : dayEnum[period.day]}</span>
            {period.closed
              ? <FormattedMessage id="components.widgets.openingHoursWidget.closed"/>
              : <span>{period.times.map((time, i) => {
                return (
                  <React.Fragment key={time + i}>
                    <time>{time.substring(0, time.length - 3)}</time>
                    {i % 2 ? null : ' - '}
                    {i % 2 ? <br/> : null}
                  </React.Fragment>
                )
              })}
              </span>
            }
          </li>
        })}
        {freeText ? <li className="int-free-text" style={{
          display: 'flex',
        }}>{freeText.free_text}</li> : null}
      </ul>
    </>
  )
}

export default OpeningPeriods;